import * as React from "react"
import Layout from "../components/common/layout"
import {  graphql } from "gatsby"
import { Helmet } from 'react-helmet'
import ListPosts from "../components/list-posts"
import FeaturePosts from "../components/feature-posts"
import SubMenu from "../components/common/sub-menu"
import Heading from "../components/common/heading"
// markup 
const Blog = ({ data }: any) => {
  const _data = data.allSampleDataJson?.nodes[0].data || []
  const subMenu = _data.find((item: any) => item.block === 'sub-menu')
  const posts = _data.find((item: any) => item.block === 'post')
  const fourthCard = _data.find((item: any) => item.block === 'fourth-card')
  const dataIconLink = _data.find((item: any) => item.block === 'icon-link');
  const link = {
    "link": "/blog",
    "text": "View all",
  }
  const featurePost  = {
    "id": 1,
    "title": "For a Longer Life and Happier Gut, Eat More Fiber",
    "image" : "/images/blog-main-01.jpg",
    "link": "/post-detail",
    "content": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt…. "
}
  return (
    <Layout>
      <Helmet
        title='Blog'
      />
      <SubMenu data={subMenu.data[0]} />
      <Heading 
        title='Feature' 
        background='bg-white'  
        />
      <FeaturePosts data={posts.data[0]} featurePost={featurePost}/>
      
      <Heading 
        link={link}
        title='Healthy Eating' 
        background='bg-white'  
        />
       
      <ListPosts data={posts.data[1]} />
      <Heading 
        link={link}
        title='Recipes' 
        background='bg-white'  
        />
        <ListPosts data={posts.data[2]} />
        <Heading 
            link={link}
            title='Weight Management' 
            background='bg-white'  
        />
       
      <ListPosts data={posts.data[1]} />
      <Heading 
       link={link}
        title='Lifestyle Diets' 
        background='bg-white'  
        />
        <ListPosts data={posts.data[2]} />
    </Layout>
  )
}

// Step 2: Export a page query
export const query = graphql`
  query {
    allSampleDataJson(filter: {jsonName: {in: ["blog-category"]}}) {
      nodes {
        jsonName
        data {
          block
          data {
            
            icons {
              icon_link {
                id
                title
                image
                link
              }
            }
            sub_menu{
              title
              links {
                id
                text
                link
                active
              }
            }
            cards {
              id
              title
              image
              link
            }
            posts {
              id
              title
              link
              content
              image
            }
          }
        }
      }
    }
  }
`
export default Blog
