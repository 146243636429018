import * as React from 'react'
import ListPosts from './list-posts'
import Post from './common/post'
const FeaturePosts = (props: any) => {
  const posts = props.data.posts;
  const feature = props.featurePost;

  return (
    <>
      <section className='feature-post'>
        <div className="container">
            <div className='row align-items-start'>
                <div className='col-lg-6 '>
                    <Post data={feature} vertical={true} ></Post>
                </div>
                <div className='col-lg-6'>
                    {
                        posts.map((post: any)=>(
                          <Post key={post.id} data={post} /> 
                        ))
                    }
                </div>
            </div>
        </div>
      </section>
      
    </>
  )
}

export default FeaturePosts
